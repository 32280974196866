import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import Vapor from 'laravel-vapor';

//import * as Sentry from "@sentry/vue";


window.Vapor = Vapor;
 
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'ElectUp';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        
        const app = createApp({ render: () => h(App, props) });


        /*
        Sentry.init({
            app,
            dsn: "https://7ff8ea2b26d686acc81fdc9ebcd70c2a@o4507596862783488.ingest.us.sentry.io/4507596872941568",
            integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            ],
            maxBreadcrumbs: 50,
            debug: false,
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/app\.electup\.com/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        */

        app
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .mount(el);


        return app;

    },
    progress: {
        color: '#a62bed',
    },
});
